
import axios from 'axios';

import {setSession} from '../../Session/sessionSlice';
import {SessionService} from '../../modules/login/Services/SessionService';
class SimulatorModel{
    constructor(country, locale, profession, specialty){
        this.profession=profession;
        this.specialty=specialty;
        this.affiliateCode=country;
        this.locale=locale;
    }
}
class SimulationService
{
    constructor()
    {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': localStorage.getItem('jwtToken')
            }
        };
        this._sessionService = new SessionService();
    }
    async ExitSimulationSession(navigate, dispatch){
        let adminHash = localStorage.getItem('simulationAdmin');
        let simulationUser = localStorage.getItem('sessionValue');
        try
        {
            localStorage.setItem('lang', 'en-US');
            localStorage.setItem('sessionValue', adminHash);
            
            await this._sessionService.GetSessionValueFromHash(navigate, dispatch);
            localStorage.removeItem('simulationAdmin');
            localStorage.removeItem('country');
            window.location.href = '/';
        }catch(err)
        {
            localStorage.setItem('sessionValue', simulationUser);
        }
        
    }
    async getTranslationBtlocale(query) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/translation/getFilteredTranslation', query, this.authHeader);
        return data;
    }
    async StartSimulation(country, locale, profession, specialty, dispatch)
    {
        let model = new SimulatorModel(country, locale, profession, specialty);
        let response = await axios.post(this.baseUrl+'/management/api/user/GetSimulationUser', model,this.authHeader)
        let replacementTOken = localStorage.getItem('sessionValue');
        localStorage.setItem('simulationAdmin', replacementTOken);
        localStorage.setItem('sessionValue', response.data.sessionHash);
        localStorage.removeItem('active_library');
        let countryCode = localStorage.getItem('country');
        let language = localStorage.getItem('lang');
        let blackListedCountries = ['UK', 'Sweden'];
        let blacklistLang = ['en-GB','sv-SE']
        if (blackListedCountries.includes(countryCode) && blacklistLang.includes(language)) {
            localStorage.clear()
            window.location.replace(window.location.origin + '/landing/'+ countryCode.toLowerCase() + '/maintenance.html');
        }

        dispatch(setSession(response.data));
        window.location.href = '/';
    }
    async GetCountryProfession(affiliateId){
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl+'/management/api/country/GetProfessionByQuery?country_code='+affiliateId, {},this.authHeader);
        return data.data;
    }
    async GetCountrySpecialty(affiliateId){
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl+'/management/api/country/GetSpecialtyByQuery?country_code='+affiliateId, {},this.authHeader);
        return data.data;
    }
    async GetCountries()
    {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl+'/management/api/user/GetAffiliateByQuery', {},this.authHeader);
        return data.data;
    }
    async GetLocales(){
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl+'/management/api/translation/GetTranslationLocales', {},this.authHeader);
        return data.data;
    }
}
export{SimulationService}