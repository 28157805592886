/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from "@material-ui/core";
import { useDropzone } from 'react-dropzone';
import uploadNewIcon from '../../../public/img/upload_new_icon.png';
import AlertDialogSlide from '../Components/ContentModal';
import { KalturaService } from '../Service/KalturaService';
const baseStyle = {};
const activeStyle = { borderColor: '#2196f3' };
const acceptStyle = { borderColor: '#00e676' };
const rejectStyle = { borderColor: '#ff1744' };

// Function for dropzone started
const DropzoneComponent = ({ SetShowPreviewOfVideo, props, SetDuration, sendDataToUploadStep, uploadToken, mediaEntry, SetKalturaID, SetThumbnailUrl, SetUploadPercentage }) => {
  const [files, setFiles] = useState([]);
  const modalFunc = React.useRef(null);

  // Function is called once the files are dropped in dropzone
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length !== 0) {
      let model = new KalturaService();
      model.UploadFile(acceptedFiles[0], uploadToken, mediaEntry, SetKalturaID, SetThumbnailUrl,
        SetUploadPercentage, SetDuration, SetShowPreviewOfVideo);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      sendDataToUploadStep(acceptedFiles)
    } else {
      errorMsg()
    }
  }, []);


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': ['.mp4', '.MP4', '.flv', '.asf', '.qt', '.mov', '.mpg', '.mpeg', '.avi', '.wmv', '.m4v', '.3gp', '.vob',],
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  // to display preview of file uploaded
  const thumbs = files.map(file => (
    <div key={file.name}>
      <img src={file.preview} alt={file.name} />
    </div>
  ));

  //Function to call ContentModel
  const errorMsg = () => {
    modalFunc.current();
  }

  // Error message to display in modal when incorrect file format is uploaded
  const data = 'The format of the video you were trying to upload is not supported. Please upload file in &#39;.mpg&#39;, &#39;.avi&#39;, &#39;.wmv&#39;, &#39;.mp4&#39;, &#39;.3gp&#39;, &#39;.vob&#39; format.';
  return (
    <Grid>
      <AlertDialogSlide modalFunc={modalFunc} sendDataToModal={data} />
      <section>
        <div className='content_dZone' {...getRootProps({ style })}>
          <input id="DropZoneAreaIdLP" {...getInputProps()} />
          <div className='upload_icon'>
            <img src={uploadNewIcon} className='content_uploadIcon' alt='upload icon' />
          </div>
          {/* <div><img src={uploadIcon} className='content_uploadIcon' alt='upload icon' /></div> */}
          <div className='upload-text'>Click here to Upload</div>
          <div className='content_font16'>or drag and drop video and podcast files</div>
          <Grid className="content_uploadSectionU">
            <Grid className="content_disclaimer">
              * Note: In Google Chrome browser, due to Lilly policies, you can't select content from a folder, instead, please drag and drop the content you want upload here.
            </Grid>
            <hr  className='content_horizon'/>
            {/* <div><img src={dottedLine} className='content_uploadIcon' alt='upload icon' /></div> */}
          </Grid>
        </div>
        <aside> {thumbs} </aside>
      </section>
    </Grid>
  )
}

export default DropzoneComponent;