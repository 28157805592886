/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ContentList from '../../shared/ContentList/ContentList';
import { SearchService } from './../Service/search-service';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Breadcrumb from '../../../layout/breadcrumb';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import loaderNewGif from '../../../public/img/new-loader.png';
import SortIcon from '@material-ui/icons/Sort';
import { useNavigate, useLocation } from "react-router-dom";
import { HomeServiceBaseMatchingModel } from '../Service/homeService';
import { useSelector, useDispatch } from 'react-redux';
import { UserBrandService } from '../Service/userBrandService';
import { BlackTriangle } from "../../shared/BlackTriangle";

export let util = { loadProducts: null };

const Search = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    let searchService = new SearchService();
    const [sortOrder, setSortOrder] = React.useState('recent');
    const [videoData, setVideoData] = React.useState([]);
    const [hasMore, setHasmore] = React.useState(false);
    const [lastPosition, setLastPosition] = React.useState(5);
    const [startPosition, setStartPosition] = React.useState(0);
    const [allvideoData, setAllvideoData] = React.useState([]);
    const [showLoader, setShowLoader] = React.useState(false);
    const [pageLoader, setPageLoader] = React.useState(false);
    const [categoryView, setCategoryView] = React.useState(false);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [categoryName, setcategoryName] = React.useState('');
    const [categoryId, setcategoryId] = React.useState('');
    const [iscategorySearch, setiscategorySearch] = React.useState(false);
    const [categorySearch, setcategorySearchName] = React.useState('');
    const [SpeakerName, setSpeakerName] = React.useState('');
    const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
    const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
    const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);
    const [brandCompletion, SetBrandCompletion] = React.useState(false);

    const [speciality, setSpeciality] = React.useState('');
    const [audiance, setaudiance] = React.useState('');

    React.useEffect(() => {
        // alert("sdf")
        let url = window.location.href;
        let searchTerm;
        setSpeakerName('')
        
        if (url.includes('category_id')) {
            searchTerm = url.split('?')

            searchTerm = searchTerm[0].split('=')

            setcategoryName(decodeURIComponent((url.split('?')[1].split('=')[1])))

            searchTerm = searchTerm[1]
            setCategoryView(true);
            setcategoryId(searchTerm)
            let searchKeyword = searchTerm ? searchTerm.toString() : props.search.toString();
            setSearchKeyword(decodeURIComponent(searchKeyword.replace(/%20/g, " ")));
        } else {
            searchTerm = url.split("/").pop();
            var urls = window.location.search;
            let speaker_name = (((window.location.search).replace("?", '')).split('='))[1];
            if (speaker_name) {
                searchTerm = searchTerm.split('?')[0];
                setSpeakerName(decodeURIComponent(speaker_name))
                setSearchKeyword(speaker_name);
            } else {
              setSearchKeyword(searchTerm.replace(/%20/g, " "));
            }
            setCategoryView(false);

        }

    }, [location, categoryView]);

    function resetQueryData() {
        setAllvideoData([]);
        setVideoData([]);
        setLastPosition(100);
        setStartPosition(0);
    }
    React.useEffect(() => {
        function getUserSelectedBrandData() {
            let userBrandService = new UserBrandService();
            userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData).then((data) => {
                
                SetBrandCompletion(true);
            });
        }
        getUserSelectedBrandData()
    }, []);
    const GetAffiliateCodesArray = () => {
        let list = []
        if (props.selectorValueSession) {
          if (props.selectorValueSession.sessionValue.payload) {
            if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
              let affiliates = props.selectorValueSession.sessionValue.payload.affiliate
              for (let i = 0; i < affiliates.length; i++) {
                if (affiliates[i]) {
                  list.push(affiliates[i].country_code);
                }
              }
            }
          }
        }
        return list;
      }
    const GetHomeServiceModel = (specialty = '', profession = '') => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginROute = '';
        let countryArray = []
        if (props.selectorValueSession) {
            if (props.selectorValueSession.sessionValue.payload) {
                if(props.selectorValueSession.sessionValue.payload.loginroute){
                    loginROute = props.selectorValueSession.sessionValue.payload.loginroute;
                    
                  }
                if (props.selectorValueSession.sessionValue.payload.decodedToken) {
                    if (props.selectorValueSession.sessionValue.payload.decodedToken) {
                        let _spec = props.selectorValueSession.sessionValue.payload.decodedToken.specialty ? props.selectorValueSession.sessionValue.payload.decodedToken.specialty : "";
                        let _prof = props.selectorValueSession.sessionValue.payload.decodedToken.profession ? props.selectorValueSession.sessionValue.payload.decodedToken.profession : "";
                        setSpeciality(_spec)
                        setaudiance(_prof)
                        let brandIdsArr = [];
                        let taIdsArr = [];
                        let indIdsArray = [];
                        if (props.selectorValueSession.sessionValue.payload.user) {
                            let userTA = props.selectorValueSession.sessionValue.payload.user.therapeutic_area;
                            let categories = userTA && Array.isArray(userTA) ? userTA : [];
                            let countryTAList = [];
                            let countryCat1Ids = [];
                            let countryCat2Ids = [];
                            if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)){
                              for(let k=0;k<props.selectorValueSession.sessionValue.payload.affiliate.length;k++){
                                if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids)){
                                  countryTAList = countryTAList.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids);
                                }
                                if(props.selectorValueSession.sessionValue.payload.affiliate[k].speciality_customization){
                                  if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids)){
                                    countryCat1Ids = countryCat1Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids);
                                  }
                                  if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids)){
                                    countryCat2Ids = countryCat2Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids);
                                  }
                                }
                                
                              }
                            }
                            if (categories?.length > 0) {
                              for (let i = 0; categories?.length > i; i++) {
                                let ta_id = categories[i].therapeutic_area_id;
                                if(countryTAList.includes(ta_id)){
                                  taIdsArr.push(ta_id);
                                }
                               
                                let brand_id = categories[i].brand_id;
                                if(countryCat1Ids.includes(brand_id)){
                                  brandIdsArr.push(brand_id);
                                }
                               
                                let ind_id = categories[i].indication_id;
                                if(countryCat2Ids.includes(ind_id)){
                                  if (ind_id !== null) {
                                    indIdsArray.push(ind_id);
                                  }
                                }
                                
                              }
                            }
                        }
                        let taArray = [];
                        if (props.allThArea.includes(',')) {
                            taArray = props.allThArea.split(',');
                        } else {
                            taArray.push(props.allThArea);
                        }
                        if(props.selectorValueSession?.sessionValue?.payload?.user?.language){
                            localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
                            if(countryName == 'Switzerland'){
                                if(localeName == 'de-CH'){
                                  localeName = 'German'
                                }else if(localeName == 'fr_CH'){
                                  localeName = 'French'
                                }
                            }else{
                              if(localeName == 'nl-BE'){
                                localeName = 'Dutch'
                              }else if(localeName == 'fr-BE'){
                                localeName = 'French'
                              }
                            }
                        }
                        let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
                        let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, _spec, _prof, taArray, 'Active', brandIdsArr, indIdsArray, '', countryName, localeName, loginROute);
                        return model;
                    }
                }
            }
        }
        let taFilter = props.allThArea;
        if (typeof taFilter == 'string') {
            taFilter = taFilter.split(',')
        }
        let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
        let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData, indIdsArrData, '', countryName, localeName, loginROute);
        return model;
    }

    util.loadProducts = async () => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginROute = '';
        if(props.selectorValueSession?.sessionValue?.payload?.loginroute){
            loginROute = props.selectorValueSession.sessionValue.payload.loginroute
          }
        if(props.selectorValueSession?.sessionValue?.payload?.user?.language){
            localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
            if(countryName == 'Switzerland'){
                if(localeName == 'de-CH'){
                  localeName = 'German'
                }else if(localeName == 'fr_CH'){
                  localeName = 'French'
                }
            }else{
              if(localeName == 'nl-BE'){
                localeName = 'Dutch'
              }else if(localeName == 'fr-BE'){
                localeName = 'French'
              }
            }
        }
        const paginateData = await searchService.getContentListCount(startPosition, lastPosition, searchKeyword, sortOrder, props.allThArea, props.selectedCountry, props.selectedLibraryid, speciality ? speciality : "", audiance ? audiance : "",countryName, localeName, loginROute, GetHomeServiceModel());
        if (paginateData.data.length == lastPosition- startPosition + 1) {
            const tempData = paginateData.data;
            if (tempData?.length > 0) {
              let tempData = (paginateData.data);
              let content_id = [];
              let array1 = videoData.concat(tempData);
              let list = []
              for (let i = 0; i < array1.length; i++) {
                  if (!content_id.includes(array1[i].content_id)) {
                      list.push(array1[i])
                      content_id.push(array1[i].content_id)
                  }
              }
                setHasmore(true);
                setVideoData(list);
                setShowLoader(false);
                setLastPosition(lastPosition+ 101);
                setStartPosition(lastPosition + 1);
                setPageLoader(false);
            } else {
              setPageLoader(false);
            }
        } else {
            const tempData = paginateData.data;
            if (tempData?.length > 0) {
              let tempData = (paginateData.data);
              let content_id = [];
              let array1 = videoData.concat(tempData);
              let list = []
              for (let i = 0; i < array1.length; i++) {
                  if (!content_id.includes(array1[i].content_id)) {
                      list.push(array1[i])
                      content_id.push(array1[i].content_id)
                  }
              }
                setVideoData(list);
            setLastPosition(lastPosition+ 101);
            setStartPosition(lastPosition + 1);
            setHasmore(false);
            setPageLoader(false);
          } else {
            setPageLoader(false);
          }
        }
        return true;
    };

    util.fetchList = (startPosition, lastPosition, searchValue, orderValue) => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginROute = '';
        if(props.selectorValueSession?.sessionValue?.payload?.loginroute){
            loginROute = props.selectorValueSession.sessionValue.payload.loginroute
          }
        if(props.selectorValueSession?.sessionValue?.payload?.user?.language){
            localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
            if(countryName == 'Switzerland'){
                if(localeName == 'de-CH'){
                  localeName = 'German'
                }else if(localeName == 'fr_CH'){
                  localeName = 'French'
                }
            }else{
              if(localeName == 'nl-BE'){
                localeName = 'Dutch'
              }else if(localeName == 'fr-BE'){
                localeName = 'French'
              }
            }
        }
        if (searchValue.length > 0 && props.allThArea != '') {
            setShowLoader(true);
            searchService.getContentListCount(startPosition, lastPosition, searchValue, orderValue, props.allThArea, props.selectedCountry, props.selectedLibraryid, speciality ? speciality : "", audiance ? audiance : "",countryName, localeName, loginROute, GetHomeServiceModel()).then(resp => {
                if (resp.data?.length > 0) {
                  let tempData = (resp.data);
                  let content_id = [];
                  let array1 = tempData; //videoData.concat(tempData);
                  let list = []
                  for (let i = 0; i < array1.length; i++) {
                      if (!content_id.includes(array1[i].content_id)) {
                          list.push(array1[i])
                          content_id.push(array1[i].content_id)
                      }
                  }
                  if(sortOrder === 'recent') {
                    list.sort((a,b) => {
                        let dateA = new Date(a.created_at)
                        let dateB = new Date(b.created_at)
                        if(dateA > dateB) {
                          return -1
                        }
                        else {
                          return 1
                        }
                      })
                  }
                  setVideoData(list);
                  setShowLoader(false);
                  setLastPosition(lastPosition+ 101);
                  setStartPosition(lastPosition + 1);
                  setPageLoader(false);
                  if(list.length<100){
                    setHasmore(false);
                    setPageLoader(false);
                  }
                } else {
                    resetQueryData();
                    setPageLoader(false);
                }
            }).catch(err => { setShowLoader(false);  setPageLoader(false); });

            searchService.getContentList(searchValue, orderValue, props.allThArea, props.selectedCountry, props.selectedLibraryid, speciality ? speciality : "", audiance ? audiance : "",countryName, localeName, loginROute, GetHomeServiceModel()).then(resp => { 
              if (resp.data?.length > 0) {
                  let content_id = [];
                  let list = []
                  let tempData = resp.data;
                  for (let i = 0; i < tempData.length; i++) {
                      if (!content_id.includes(tempData[i].content_id)) {
                          list.push(tempData[i])
                          content_id.push(tempData[i].content_id)
                      }
                  }
                  if(sortOrder === 'recent') {
                    list.sort((a,b) => {
                        let dateA = new Date(a.created_at)
                        let dateB = new Date(b.created_at)

                        if(dateA > dateB) {
                          return -1
                        }
                        else {
                          return 1
                        }
                      })
                  }
                  setAllvideoData(list);
                  if(list.length<100){
                    setHasmore(false);
                    setPageLoader(false);
                  }
                } else {
                    resetQueryData();
                }
            }).catch(err => {});
            setShowLoader(false);
            return true;
        } else {
            setPageLoader(false);

        }
    }

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
        resetQueryData();
    };

    React.useEffect(() => {
      if(props.selectorValueSession){
        if(props.selectorValueSession.sessionValue){
          if(props.selectorValueSession.sessionValue.payload && Object.keys(props.selectorValueSession.sessionValue).length>0){
            props.setFilter('All')
            GetHomeServiceModel()
            window.scrollTo(0, 0);
            setPageLoader(true);
            resetQueryData();
            util.fetchList(0, 100, searchKeyword, sortOrder);
    
          }
        }
      }
       
        return () => {
            props.SetSearchName('');
        }
    }, [searchKeyword, sortOrder, props.allThArea, props.selectedCountry, props.selectedLibraryid, categoryId, props.selectorValueSession, speciality, audiance]);

    let str1 = props.translations['Results for search'] ? props.translations['Results for search'] : 'Results for search'
    const sectionTitle = str1 + ' "' + (iscategorySearch ? categorySearch : decodeURIComponent(searchKeyword)) + '"';
    var searchString = window.location.href.split('/')[window.location.href.split('/').length - 1];
    const BreadcrumbData = [
        { value: 'Home', path: '/home' },
        // { value: 'Search', path: '/search/' + searchString }
        { value: 'Search' }
    ]
    return (
        <>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <Grid className='outer-wrapper'>
                {categoryView ?
                    <Grid className='searchSortSec'>
                        <SortIcon className='searchSort' />
                        <span className='searchSortTxt'> {categoryName == '' ? searchKeyword : props.translations[categoryName] ? <BlackTriangle title={props.translations[categoryName]} /> : <BlackTriangle title={categoryName} />} </span>
                        <img alt='close' className='searchClose' onClick={() => navigate('/home')} src={require('../../../public/img/CrossGrey@3x.png')} />
                    </Grid> : null}

                <Paper className='search_header'>
                    <div className='secTitle'>
                        {categoryName === '' && SpeakerName === '' ? sectionTitle : ''}
                        {SpeakerName !== '' && categoryName === '' ? SpeakerName : props.translations[categoryName] ? <BlackTriangle title={props.translations[categoryName]} /> : <BlackTriangle title={categoryName} />}
                        <span><b>|</b> {allvideoData.length} {allvideoData.length < 2 && (props.selectedCountry === 'BR'|| props.selectedCountry === 'FR') ? props.translations['content found'] ? props.translations['content found'] : 'content found' : props.translations['contents found'] ? props.translations['contents found'] : 'contents found'}</span>
                    </div>
                    {categoryView ? null :
                        <div className='sortOrderDP'>
                            <span>{props.translations['Sort By'] ? props.translations['Sort By'] : 'Sort By'}:</span>
                            <Select
                                MenuProps={{
                                    className: 'custom-sortOrderDPItem',
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                className="selectDP" value={sortOrder} onChange={handleSortOrderChange} disableUnderline='true'
                            >
                                <MenuItem value='recent'>{props.translations['Most Recent'] ? props.translations['Most Recent'] : 'Most Recent'}</MenuItem>
                                <MenuItem value='popular'>{props.translations['Most Popular'] ? props.translations['Most Popular'] : 'Most Popular'}</MenuItem>
                            </Select>
                        </div>}
                </Paper>
                <div style={{ clear: 'both' }}>
                    {pageLoader ? <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate m20Top' alt='icon' /></Grid> :
                        <>
                            {
                                videoData.length > 0 ?
                                    <ContentList
                                        contentData={videoData}
                                        loadProducts={util.loadProducts}
                                        hasMore={hasMore}
                                        showLoader={showLoader}
                                        translations={props.translations}
                                    />
                                    : <Grid className="ml10">{props.translations['No Content Found'] ? props.translations['No Content Found'] : 'No Content Found'}</Grid>
                            }
                        </>
                    }
                </div>
            </Grid>
        </>
    )
}

export default Search