/* eslint-disable eqeqeq */
/* eslint-disable no-sequences */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from '@material-ui/core/Grid';
import RightArrowIcon from '../../../public/img/right-arrow.svg';
import LeftArrowIcon from '../../../public/img/left-arrow.svg';
import './carousel.css';
import Video from './Video';
import PlayList from './PlayList';
import Podcast from './Podcast';
import LiveEvent from './LiveEvent';

const MostRecent = (props) => {
    /** Carousel Next Arrow  */
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={RightArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        marginTop: '-4%',
                        fontSize: '36px',
                        marginRight: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                        display: 'inline-block',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel Previous Arrow  */
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={LeftArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        marginTop: '-4%',
                        fontSize: '36px',
                        marginLeft: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                        display: 'inline-block',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel settings  */
    const settings1 = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: props.slides,
        arrows: true,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: false,
                    infinite: true,
                    variableWidth: true,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    centerMode: false,
                    variableWidth: true,
                    arrows: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                    infinite: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    initialSlide: 0,
                    centerMode: false,
                    variableWidth: true,
                    infinite: false,
                }
            }
        ]
    };
    const time = new Date('2022-08-03');
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
    return (
        /** Carousel Slider Section  */
        <Grid className='slickLgSec'>
            {props.contentData.length > 0 || props.liveEvents.length > 0 ?
                <Slider {...settings1}>
                    {props.liveEvents.length > 0 ? props.liveEvents.map((eventData, index) => {
                        return (<LiveEvent slides={3} event={eventData} translations={props.translations}/>)
                    }) : null}
                    {props.contentData.length > 0 ? props.contentData.map((content, map) => {
                        return (content.content_type == 'podcast' ? (<Podcast filter={props.filter} key={map} podcast={content} speaker={content.speakers_id} slides={3}  newLabelWatchedContent={props.newLabelWatchedContent} newLabelWatchingContent={props.newLabelWatchingContent} translations={props.translations}></Podcast>)
                            : content.content_type == 'video' ? (<Video filter={props.filter} key={map} video={content} speaker={content.speakers_id} slides={3} newLabelWatchedContent={props.newLabelWatchedContent} newLabelWatchingContent={props.newLabelWatchingContent} translations={props.translations}></Video>) :
                                content.content_type == 'playlist' ? (<PlayList filter={props.filter} key={map} playlist={content} speaker={content.speakers_id} slides={3} translations={props.translations}></PlayList>) : null
                        )
                    }) : null}
                </Slider>
                : <Grid className='notDataFound text-left'>{props.translations['No Content Found'] ? props.translations['No Content Found'] : 'No Content Found'}</Grid>}
        </Grid>

    );
}

export default MostRecent;
